//https://github.com/chrislabarge/color-picker-demo

import ApplicationController from "./application_controller";
import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/nano.min.css";

export default class extends ApplicationController {
    static targets = ["picker", "input"]

    initialize() {
        console.log("ColorPickerController initialized");
        this.initPicker();
    }

    initPicker() {
        this.picker = Pickr.create({
            el: this.pickerTarget,
            theme: 'nano',
            default: this.inputTarget.value,
            // Translations, these are the default values.
            i18n: {

                // Strings visible in the UI
                'btn:save': 'Speichern',
                'btn:cancel': 'Abbrechen',
                'btn:clear': 'Zurücksetzen'
            },

            components: {
                preview: true,
                opacity: false,
                hue: true,

                interaction: {
                    hex: true,
                    rgba: true,
                    hsla: false,
                    hsva: false,
                    cmyk: false,
                    input: true,
                    clear: false,
                    save: true,
                },
            },
        });



        this.picker.on('save', (color, _instance) => {
            this.inputTarget.value = color.toHEXA().toString();

            this.picker.hide();
        });
    }
}