import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'


export default class extends ApplicationController {
    static targets = [ "input" ]


    connect() {
        this.initializeTomSelect();
    }

    disconnect() {
        this.destroyTomSelect();
    }


    initializeTomSelect() {
        const model = this.element.dataset.model;


        // if placeholder is set, use it, otherwise use the default
        let placeholder = this.inputTarget.dataset.placeholder ? this.inputTarget.dataset.placeholder : "Suchen";
        let plugins = {
        };

        new TomSelect(this.inputTarget, {
            plugins: plugins,
            create: false,
            valueField: 'id',
            labelField: 'text',
            searchField: 'text',
            closeAfterSelect: true,
            selectOnTab: true,
            placeholder: placeholder, //"Bitte auswählen",
            load: (query, callback) => {
                if (!query.length) return callback();
                fetch(`/backend/search?term=${encodeURIComponent(query)}&model=${model}`)
                    .then(response => response.json())
                    .then(json => {
                        callback(json);
                    }).catch(() => {
                    callback();
                });

            },
            render: {
                no_results: function (data, escape) {
                    return '<div class="no-results">Keine Ergebnisse für "' + escape(data.input) + '". Entfernen mit der ␛-Taste</div>';
                },
                option_create: function(data, escape) {
                    if (allowCreate) {
                        return '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen &hellip;</div>';
                    } else {
                        return ''; // Return an empty string to hide the "Add" option
                    }
                },
                option: function(data, escape) {
                    let subtext = data.subtext ? '<br/><span class="subtext text-xs text-gray-400 truncate">' + escape(data.subtext) + '</span>' : '';
                    return '<div class="option">' +
                        '<span class="title text-sm">' + escape(data.text) + '</span>' +
                        subtext +
                        '</div>';
                },
                item: function(data, escape) {
                    return '<div class="item text-sm">' + escape(data.text) + '</div>';
                },
                render: {
                    loading_more: function(data, escape) {
                        return `<div class="loading-more-results py-2 d-flex align-items-center"><div class="spinner"></div> Wird geladen</div>`;
                    }
                },
            },
        });

    }

    destroyTomSelect() {
        this.tomselect.destroy();
    }
}