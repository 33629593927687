import ApplicationController from "./application_controller";
import { validate } from "email-validator"


export default class extends ApplicationController {
  static targets = ["email"]

  validate() {
    const email = this.emailTarget.value
    if (!validate(email)) {
      this.emailTarget.setCustomValidity("Invalid email address")
    } else {
      this.emailTarget.setCustomValidity("")
    }
  }
}
